import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DispensaryListTemplateBase from 'templates/dispensaryListingBase'

function getDispensariesFromProps(data) {
	const { allDispensariesJson = null } = data

	if (!allDispensariesJson || !allDispensariesJson.edges) {
		return []
	}
	return allDispensariesJson.edges.map(edge => ({ ...edge.node.dispensary }))
}

function DispensaryListingTemplate({ data, location, pageContext }) {
	const { state, city } = pageContext
	const [dispensaries, setDispensaries] = useState([])
	const [mapConfig, setMapConfig] = useState({ defaultMapZoom: 12, geoLocation: { latitude: 0, longitude: 0 } })

	useEffect(() => {
		setDispensaries(getDispensariesFromProps(data))
	}, [])

	useEffect(() => {
		if (dispensaries.length > 0) {
			const {
				geoLocation: { _latitude: latitude, _longitude: longitude },
			} = dispensaries[0]
			setMapConfig({ defaultMapZoom: 12, geoLocation: { latitude, longitude } })
		}
	}, [dispensaries])

	const {
		defaultMapZoom,
		geoLocation: { latitude, longitude },
	} = mapConfig
	return (
		<DispensaryListTemplateBase
			defaultCenter={[latitude, longitude]}
			defaultMapZoom={defaultMapZoom}
			dispensaries={dispensaries}
			location={location}
			pageContext={pageContext}
			pageTitle={`Find Marijauna Dispensaries in ${city}, ${state}`}
			pageHeader={`Dispensaries in ${city}, ${state}`}
		/>
	)
}
DispensaryListingTemplate.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
}
export const pageQuery = graphql`
	query dispensariesByCities($city: String!, $skip: Int!, $limit: Int!) {
		allDispensariesJson(
			filter: { dispensary: { city: { eq: $city } } }
			skip: $skip
			limit: $limit
			sort: { fields: dispensary___name }
		) {
			edges {
				node {
					dispensary {
						id
						address1
						address2
						averageRating
						geoLocation {
							_latitude
							_longitude
						}
						city
						name
						phone
						state
						slug
						totalReviews
						zip
					}
				}
			}
		}
	}
`
export default DispensaryListingTemplate
